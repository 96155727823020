import {
  VehicleSelectionDispatchTypes,
  VEHICLE_SELECTION_LOADING,
  VEHICLE_SELECTION_SUCCESS,
} from "../actions/vehicleSelectionActionTypes";

interface DefaultStateInterface {
  hasData?: boolean;
  vehicleSelection?: string;
  loading: boolean;
}

const defaultState: DefaultStateInterface = {
  hasData: false,
  loading: false,
};

const vehicleSelectionReducer = (
  state: DefaultStateInterface = defaultState,
  action: VehicleSelectionDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case VEHICLE_SELECTION_LOADING:
      return {
        loading: true,
      };
    case VEHICLE_SELECTION_SUCCESS:
      return {
        loading: false,
        vehicleSelection: action.payload,
        hasData: true,
      };
    default:
      return state;
  }
};

export default vehicleSelectionReducer;
