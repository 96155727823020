import {
  MetricsDispatchTypes,
  METRICS_LOADING,
  METRICS_SUCCESS,
  Metrics,
} from "../actions/metricsActionTypes";

interface DefaultStateInterface {
  hasData?: boolean;
  metrics?: Array<Metrics>;
  loading: boolean;
}

const defaultState: DefaultStateInterface = {
  hasData: false,
  loading: false,
};

const metricsReducer = (
  state: DefaultStateInterface = defaultState,
  action: MetricsDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case METRICS_LOADING:
      return {
        loading: true,
      };
    case METRICS_SUCCESS:
      return {
        loading: false,
        metrics: action.payload,
        hasData: true,
      };
    default:
      return state;
  }
};

export default metricsReducer;
