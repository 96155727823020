import { useContext, useEffect, useState } from "react";
import ThemeContext from "../../theme/ThemeContext";
const SPLogoLightmode = require("../../logos/SPLogoLightmode.png");
const SPLogoDarkmode = require("../../logos/SPLogoDarkmode.png");
import StealthIntellLogoLight from "../../logos/StealthIntellLogo.svg";
import StealthIntellLogoDark from "../../logos/StealthIntellLogo-White.svg";
import "./AppFooterStyles.scss";

export default function AppFooter() {
  const { darkmode, footer } = useContext(ThemeContext);
  const [desktopDisplay, setDesktopDisplay] = useState(true);

  // Get window dimension to handle component resizing/position
  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () => {
    const width = window.innerWidth;
    if (width <= 950) {
      setDesktopDisplay(false);
    } else {
      setDesktopDisplay(true);
    }
  };

  return (
    <footer className={darkmode ? "footer-dark" : "footer-light"}>
      <div
        style={{
          marginRight: "50px",
          bottom: "15px",
          position: "fixed",
          right: 130,
        }}
      >
        {"Telematics Powered by"}
      </div>

      <img
        style={{
          marginRight: "10px",
          bottom: "5px",
          position: "fixed",
          right: 15,
          height: "40px",
        }}
        src={darkmode ? StealthIntellLogoDark : StealthIntellLogoLight}
      />
    </footer>
  );
}
