export const POPUP_VIEW_LOADING = "POPUP_VIEW_LOADING";
export const POPUP_VIEW_SUCCESS = "POPUP_VIEW_SUCCESS";

export type PopupView = {
  viewRoute: boolean;
};

export interface PopupViewLoading {
  type: typeof POPUP_VIEW_LOADING;
}

export interface PopupViewSuccess {
  type: typeof POPUP_VIEW_SUCCESS;
  payload: boolean;
}

export type PopupDispatchTypes = PopupViewLoading | PopupViewSuccess;
