import {
  PopupDispatchTypes,
  POPUP_VIEW_LOADING,
  POPUP_VIEW_SUCCESS,
  PopupView,
} from "../actions/popupActionTypes";

interface DefaultStateInterface {
  hasData?: boolean;
  popupView?: boolean;
  loading: boolean;
}

const defaultState: DefaultStateInterface = {
  hasData: false,
  loading: false,
};

const popupReducer = (
  state: DefaultStateInterface = defaultState,
  action: PopupDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case POPUP_VIEW_LOADING:
      return {
        loading: true,
      };
    case POPUP_VIEW_SUCCESS:
      return {
        loading: false,
        popupView: action.payload,
        hasData: true,
      };
    default:
      return state;
  }
};

export default popupReducer;
