import {
  SettingsDispatchTypes,
  SETTINGS_LOADING,
  SETTINGS_FAILURE,
  SETTINGS_SUCCESS,
  Settings,
} from "../actions/settingsActionTypes";

interface DefaultStateInterface {
  loading: boolean;
  settings?: Array<Settings>;
  hasData?: boolean;
}

const defaultState: DefaultStateInterface = {
  loading: false,
  hasData: false,
};

const settingsReducer = (
  state: DefaultStateInterface = defaultState,
  action: SettingsDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case SETTINGS_FAILURE:
      return {
        loading: false,
      };
    case SETTINGS_LOADING:
      return {
        loading: true,
      };
    case SETTINGS_SUCCESS:
      return {
        loading: false,
        settings: action.payload,
        hasData: true,
      };
    default:
      return state;
  }
};

export type VehicleRootState = ReturnType<typeof settingsReducer>;

export default settingsReducer;
