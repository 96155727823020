import React from "react";

// theme files
import on2next from "./on2next";
import on2nextDark from "./on2next-dark";

const defaults = {
  on2next,
  dark: on2nextDark,
};

export default React.createContext(defaults["on2next"]);
