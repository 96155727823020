export const VEHICLE_SELECTION_LOADING = "VEHICLE_SELECTION_LOADING";
export const VEHICLE_SELECTION_SUCCESS = "VEHICLE_SELECTION_SUCCESS";

export type VehicleSelection = {
  vehicleSelection: string;
};

export interface VehicleSelectionLoading {
  type: typeof VEHICLE_SELECTION_LOADING;
}

export interface VehicleSelectionSuccess {
  type: typeof VEHICLE_SELECTION_SUCCESS;
  payload: string;
}

export type VehicleSelectionDispatchTypes =
  | VehicleSelectionLoading
  | VehicleSelectionSuccess;
