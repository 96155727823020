import {
  VehicleListDispatchTypes,
  VEHICLE_LIST_LOADING,
  VEHICLE_LIST_FAILURE,
  VEHICLE_LIST_SUCCESS,
} from "../actions/vehicleListActionTypes";

interface DefaultStateInterface {
  loading: boolean;
  vehicleList?: Array<any>;
  hasData?: boolean;
}

const defaultState: DefaultStateInterface = {
  loading: false,
  hasData: false,
};

const vehicleListReducer = (
  state: DefaultStateInterface = defaultState,
  action: VehicleListDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case VEHICLE_LIST_FAILURE:
      return {
        loading: false,
      };
    case VEHICLE_LIST_LOADING:
      return {
        loading: true,
      };
    case VEHICLE_LIST_SUCCESS:
      return {
        loading: false,
        vehicleList: action.payload,
        hasData: true,
      };
    default:
      return state;
  }
};

export type VehicleRootState = ReturnType<typeof vehicleListReducer>;

export default vehicleListReducer;
